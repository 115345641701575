

































































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
// utilities
import { Chart } from "highcharts-vue";
import Highcharts, { Series } from "highcharts";
import dateHelper from "@/Scripts/utilities/date-helper";

//  types
import { IDataSerie } from "@/types/highcharts/dataSerie";
import { SpeedLogSensorQuality } from "@/types/dataQuality/SpeedLogSensorQuality";

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class SensorQualityChart extends Vue {
  @Prop({ required: true }) speedLogData!: SpeedLogSensorQuality;
  @Prop({ required: true }) dateRange!: { from: string; to: string };
  @Prop() loading!: boolean;

  chart!: any;
  chartLoaded = false;

  //  @Getters
  get ChartOptions(): any {
    const ctx = this;
    if (!this.chartLoaded) return { chart: ctx.chartSettings, title: { text: null }, series: [] };
    const options = {
      chart: ctx.chartSettings,
      title: { text: null },
      legend: {
        enabled: false,
      },
      yAxis: {
        min: -20,
        // max: 20,
        title: {
          text: "",
        },
        labels: {
          format: "{value} %",
          style: { color: "#331714" },
        },
      },
      xAxis: {
        title: {
          text: null,
        },
        type: "datetime",
        minRange: 1,
        labels: {
          y: 35,
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      plotOptions: {
        area: {
          threshold: 0,
          marker: {
            enabled: false,
          },
        },
        series: {
          showInLegend: true,
          dataLabels: { enabled: false },
          marker: {
            radius: 2,
            fillColor: "#FFFFFF",
            lineWidth: 1,
            lineColor: "#FFFFFF",
            states: {
              select: {
                radius: 2,
              },
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#000",
        borderWidth: 0,
        borderRadius: 13,
        shadow: false,
        style: {
          color: "#fff",
          fontSize: 14,
        },
        formatter: function () {
          const $this: any = this;
          return `
            <div style="line-height: 18px;">
                <span style="margin-bottom: 5px; font-size: 12px;">${dateHelper.getFormatedDateString($this.point.x)}</span> <br />
                <span style="font-size: 12px;">${Number($this.point.y.toFixed(2))}%</span>
            </div>
            `;
        },
      },
      series: this.seriesData,
      credits: { enabled: false },
      exporting: { enabled: false },
    };

    return options;
  }

  get chartSettings(): any {
    if (!this.chartLoaded) return {};
    const ctx = this;
    return {
      type: "line",
      zoomType: "x",
      spacingRight: 0,
      spacingLeft: 0,
      spacingTop: 28,
      // animation: false,
      height: 315,
      style: { fontFamily: "Helvetica Neue" },
      events: {
        selection: function (event: any) {
          //  trigger onChartZoom event on zoom
          ctx.$emit("onEventChartZoom", event);
        },
      },
    };
  }

  get seriesData(): IDataSerie[] {
    if (!this.speedLogData?.data) return [] as IDataSerie[];
    const baseline = {
      name: "Baseline",
      type: "line",
      dashStyle: "dash",
      color: "#CFC9C4",
      enableMouseTracking: false,
      lineWidth: 1,
      cropThreshold: 9999,
      marker: { enabled: false },
      zIndex: 4,
      data: [[Date.parse(`${this.dateRange.from}`), 0] as [number | null, number | null], [Date.parse(`${this.dateRange.to}`), 0] as [number | null, number | null]],
    };
    let series: IDataSerie[] = [];

    series = [this.speedLossPoints, baseline];

    return series;
  }

  get speedLossPoints(): IDataSerie {
    return {
      name: "Deviation from baseline",
      type: "line",
      color: "#17b26a",
      zIndex: 1,
      enableMouseTracking: true,
      cropThreshold: 9999,
      marker: {
        symbol: "circle",
      },
      tooltip: {
        outside: true,
        useHTML: true,
        enabled: true,
        borderWidth: 0,
        shadow: false,
        headerFormat: "<small>{point.key}</small><br>",
        pointFormat: "{point.y}",
        valueDecimals: 1,
        valueSuffix: " %",
        xDateFormat: "%d. %b, %Y",
        backgroundColor: "#000",
        style: {
          fontSize: "12px",
          color: "#fff",
        },
      },
      zones: [
        // {
        //   value: 3,
        //   color: {
        //     linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        //     stops: [
        //       [0, "#db4b39"],
        //       [0.1, "#e26343"],
        //       [0.2, "#f9b562"],
        //       [0.3, "#f8ca6a"],
        //       [0.4, "#d9c76a"],
        //       [0.5, "#b8c369"],
        //       [0.6, "#91bf6a"],
        //       [0.7, "#67bb6a"],
        //       [0.8, "#4cb86a"],
        //       [0.9, "#24b369"],
        //       [1, "#17b26a"],
        //     ],
        //   },
        // },
        // {
        //   value: 0.48,
        //   color: "#7cb5ec",
        // },
        // {
        //   value: -1,
        //   color: {
        //     linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        //     stops: [
        //       [0, "#17b26a"],
        //       [0.1, "#24b369"],
        //       [0.2, "#4cb86a"],
        //       [0.3, "#67bb6a"],
        //       [0.4, "#91bf6a"],
        //       [0.5, "#b8c369"],
        //       [0.6, "#d9c76a"],
        //       [0.7, "#f8ca6a"],
        //       [0.8, "#f9b562"],
        //       [0.9, "#e26343"],
        //       [1, "#db4b39"],
        //     ],
        //   },
        // },
      ],
      data: this.speedLogData?.data.filter(item => item.bias !== null && item.bias !== 0).map(item => [Date.parse(`${item.timeStamp}`), item.bias]) ?? [],
    };
  }

  get noData() {
    return this.seriesData.length === 0;
  }

  //  @Methods
  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
  }
}
